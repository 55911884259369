.nagios {
  background-image: url("./nagios.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  height: 65vh;
  font-size: 60px;
  width: 100%;
}

.nagios h1 {
  padding-top: 220px;
}

.nagiosCard{
  margin-left: 90px;
}