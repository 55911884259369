.login-register {
    text-align: center;
    display: flex;
    min-height: 140vh;
    align-items: center;
    justify-content: center;
    color: var(--login_body);
    background-image: var(--login_background);
  }
  
  
  label {
    text-align: left;
    padding: 0.25rem 0;
  }
  
  input {
    margin: 0.5rem 0;
    padding: 1rem;
    border: none;
    border-radius:10px ;
  }
  
  button {
    border: none;
    background-color: var(--login_body);
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    color: #7439db;
  }
  
  .link-btn{
    background: none ;
    color: var(--login_body);
    text-decoration: underline;
  }
  
  .error-message {
    height: 20px; /* Adjust as needed */
    color: red;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 768px) {
    .error-message {
      width: 100%;
    }
  }
  .auth-form-container, .login-form, .register-form{
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 600px) {
    .auth-form-container{
      padding: 5rem;
      border: 3px solid var(--login_body);
      border-radius: 10px;
      margin: 0.5rem;
    }
  }