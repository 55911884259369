.contact-us {
  background-image: url("./contactus.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  height: 30vh; /* set the height to 100% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: black;
}
