.container {
  max-width: 1200px;
  margin: 10px;
  padding: 50px;
  margin-top: -20px;
}

.col-12.col-md-6.d-flex.align-items-center.text-center {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 60px;
  flex: 0 0 100%;
}

.img-thumbnail {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.home {
  background-color: var(--body_background);
  margin-top: 20px;
}

.primary {
  background-image: url("./landingpage/DataShur-Proc.jpg");
  position: relative;
  width: 50%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  height: 80vh;
  display: flex;
  align-items: center;
  left: 5%;
  margin-top: -20px;
  border: none;
}

.primary__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.primary__title {
  position: absolute;
  bottom: 15%;
  right: 10%;
  color: white;
  font-size: 4rem;
  padding: 15px;
}

.primary h1,
.primary h2 {
  text-shadow: 2px;
  color: black;
  font-size: 40px;
  white-space: nowrap;
  margin-left: 10px;
  padding: 10%;
  position: absolute;
  top: 20%;
  right: -60%;
}

.primaryText p {
  font-size: 16px;
  right: -48%;
  position: absolute;
  font-size: 18px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  top: 45%;
}

.primary button {
  margin-left: 112vh;
  margin-top: 35%;
  color: white;
  background-color: blue;
  border-radius: 7px;
  background-color: var(--body_background);
  margin-top: 20px;
}

.primary {
  background-image: url("./landingpage/DataShur-Proc.jpg");
  position: relative;
  width: 50%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  height: 80vh;
  display: flex;
  align-items: center;
  left: 5%;
  margin-top: 10%;
  border: none;
}

.primary__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.primary__title {
  position: absolute;
  bottom: 15%;
  right: 10%;
  color: white;
  font-size: 4rem;
  padding: 15px;
}

.primary h1,
.primary h2 {
  text-shadow: 2px;
  color: black;
  font-size: 40px;
  white-space: nowrap;
  margin-left: 10px;
  padding: 10%;
  position: absolute;
  top: 20%;
  right: -60%;
}

.primaryText p {
  font-size: 16px;
  right: -48%;
  position: absolute;
  font-size: 18px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  top: 45%;
}

.primary button {
  margin-left: 112vh;
  margin-top: 35%;
  color: white;
  background-color: blue;
  border-radius: 7px;
}

/* Banner text */
.banner {
  background-color: #0073e6;
  color: #fff;
  text-align: center;
  padding: 20px;
}
.banner h1 {
  font-size: 24px;
  animation: splitTextH1 1s forwards; 
}
.banner p {
  font-size: 18px;
  animation: splitTextP 1s forwards; 
}

@keyframes splitTextH1 {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
}
@keyframes splitTextP {
  0% {
    transform: translateY(100px); 
    opacity: 0;
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
}