.services {
  background-image: url("./servicesPic.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  height: 30vh;
  font-size: 55px;
}
.myServiceContainer a {
  text-decoration: none;
}

.myServiceContainer h4 {
  color: black;
}

.servicesCard {
  border-radius: 6px;
}

.servicesCard:hover {
  padding: 1.5rem !important;
  background-color: #dbdbdb;
  cursor: pointer;
  border-radius: 6px;
}¨
.nagiosVideo {
  box-sizing: border-box;
  overflow: hidden;
}
