:root {
  --body_background: white;
  --body_color: black;
  --link_color: navy;
  --login_background: linear-gradient(79deg, #7e48dc, #c87abf 48%, #f7a163);
  --login_body: black;
}

[data-theme="dark"] {
  --body_background: black;
  --body_color: white;
  --link_color: cyan;
  --login_background: linear-gradient(79deg, #4a199e, #8d3683 48%, #ae5d23);
  --login_body: white;
}

* {
  transition: all 0.2s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
