* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 190vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}




/*-------------- Style are removed --------------*/

/* .home {
  background-color: var(--body_background);
  
} */

/* .services {
  background-image: url('./images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .products {
  background-image: url('./images/img-3.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .contact-us {
  background-image: url('./images/img-4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .sign-up {
  background-image: url("./images/img-7.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .marketing {
  background-image: url("./images/img-6.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .consulting {
  background-image: url("./images/img-5.jpg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

/* .about-us {
  background-image: url("./images/img-4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  height: 100vh; /* set the height to 100% of the viewport height */
  /* display: flex;
  justify-content: center;
  align-items: center;
} */ 
