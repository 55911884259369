.about-us {
  background-image: url("./aboutUspic.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
