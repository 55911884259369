.resNav {
  text-decoration: none;
  list-style: none;
}
.resNav a {
  text-decoration: none;
  color: navy;
  font-size: 18px;
  margin-top: 4px;
}
.resNav li:hover {
  background: #1973ee;
  padding: 6px 16px 6px 16px;
  border-radius: 5px;
  color: white;
  transition: 1;
}

.myBitton button {
  color: navy;
}

.logoText{
  text-decoration: none;
  color: black;
}
