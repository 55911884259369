.myTietoTurvaContainer {
  background-image: url("./tietoturvaPic.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  height: 65vh;
  font-size: 100px;
}

.myTietoTurvaContainer h4 {
  justify-content: center;
  align-items: center;
  font-size: 40px;
  padding-top: 230px;
}
