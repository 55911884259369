/* .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: blue;
    cursor: pointer;
    font-size: 20px; 
    font-size: 20px;
    font-weight: 40px;
    padding: 15px;
    border-radius: 50px;
  }
 */
 .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
  }
  